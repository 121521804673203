
import { defineComponent, onMounted, computed, ref, reactive, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import UserDetail from "@/views/user/modals/UserDetail.vue"
import { User } from "@/types/UserModel";
import { ElMessageBox } from "element-plus";
import Swal from "sweetalert2";

export default defineComponent({
  name: "user-list",
  components: {
      UserDetail
  },
  setup() {
    const users = ref<any>([]);;
    const selectedUser = ref({} as User);
    const roles = ref<any>([]);

    const getRoles = async() =>{
        ApiService.setHeader(); 
        await ApiService.get("user/roles").then(({data})=>{
            roles.value = data.data;
        })
    }

    const getUsers = async () =>  {
        ApiService.setHeader();         
        ApiService.get("user")
            .then(({ data }) => {
                users.value = data.data;
            })
            .catch(({ response }) => {
                console.log('getUsers error => '+response.data.errors);                    
            });
    }

    const pageSize = 15;
    let page = reactive({pageValue : 1});

    const pagedTableDate = computed(()=>{
      return users.value.filter(data => !search 
                                || data.name.toLowerCase().includes(search.searchValue.toLowerCase())
                                || data.email.toLowerCase().includes(search.searchValue.toLowerCase())
                                || data.role?.toLowerCase().includes(search.searchValue.toLowerCase()))
                            .slice(pageSize * page.pageValue - pageSize, pageSize * page.pageValue);
     })

    const search = reactive({searchValue : ''});

    const userDetail = (user) =>{
        selectedUser.value = user;
        let userDetailBtn = document.getElementById('user_detail_button');
        userDetailBtn?.click();
    }

    const updateUser = (user) =>{
        users.value.find(x=> x.id == user.id)[0] = user;
    }

    const handleEdit = (row) => {
        userDetail(row)
    };

    const handleDelete = (row) => {
      ElMessageBox.confirm('Are you sure to delete this user?')
            .then(() => {
                ApiService.setHeader();
                ApiService.delete("user/"+row.id)
                    .then(() => {
                        Swal.fire({
                            text: "user is deleted successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                users.value = users.value.filter(x=>x.id != row.id);
                            });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to delete the assessment. "+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    });
            });
    };

    const setPage = (val) => {
      page.pageValue = val;
    }

    onBeforeMount(async() =>{
        await getRoles();
        await getUsers();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("User List", ["User"]);
    });

    return {
      handleEdit,
      handleDelete,
      setPage,
      userDetail,
      users,
      pagedTableDate,
      pageSize,
      search,
      roles,
      selectedUser,
      updateUser,
      getUsers
    };
  },
});
