
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { User } from "@/types/UserModel";

export default defineComponent({
  name: "create-record-modal",
  components: {
  },
  props:{
    user : User,
    roles : [],
    reloadUsers: {
      type:Function,
      default: () => ({}),
    }, 
  },
  emits:['updateUser'],
  setup(props, {emit}) {
    const userData = ref({} as any);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const confirmPassword = ref('');

    watch(() => props.user, async()=>{
        userData.value = props.user;
    });

    const editUser = async (formData) => {
        ApiService.setHeader();
        if(userData.value && userData.value.id !==0)
        {
            ApiService.update("user",userData.value.id, formData)
                .then(()=>{
                    userData.value.password= '';
                    confirmPassword.value = '';
                    emit('updateUser', userData);
                })
                .catch(({ response }) => {
                    loading.value = false;
                    console.log(response);
                    Swal.fire({
                    text: "Failed to edit User",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
        else
        {
          ApiService.post("user", formData)
                .then(()=> { props.reloadUsers();})
                .catch(({ response }) => {
                    loading.value = false;
                    console.log(response);
                    Swal.fire({
                    text: "Failed to create the new user",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
    }

    const submit = () => {
        let errorMessage = '';
        if(!userData.value.name || !userData.value.email ){
            errorMessage = "Please fill out all required fields";
        }
        else if((userData.value.id > 0 && userData.value.password && userData.value.password != confirmPassword.value)
                ||(userData.value.id === 0 && userData.value.password != confirmPassword.value)){
            errorMessage = 'password and confirmed password are not matched';
        }
        else if(userData.value.id > 0 && !userData.value.password){
            delete userData.value.password;
        }

        if(errorMessage !== ''){
            Swal.fire({
                text: errorMessage,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            });

            return false;
        }
        else{
            loading.value = true;
            editUser(userData.value).then(() => {
                Swal.fire({
                    text: "User change successfully submitted!",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    let userDetailCancelBtn = document.getElementById('kt_modal_new_target_cancel');
                    userDetailCancelBtn?.click();
                });
            });
        }
    };

    return {
      userData,
      submit,
      loading,
      formRef,
      confirmPassword
    };
  },
});
