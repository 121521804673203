<template>
  <div
    class="modal fade"
    id="modal_user_detail"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <div class="modal-content">
        <div class="modal-header">
            <h2>
                <span v-if="user.id===0" >Create</span>
                <span v-else>Edit</span> user
            </h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
          <el-form
            id="edit_invoice_form"
            :model="userData"
            ref="formRef"
            class="form"
          >
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Name</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="name">
                <input
                    v-model="userData.name"
                    style="width:100%;"
                    class="form-control form-control-lg form-control-solid"
                />
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Email</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="email">
                <input
                    v-model="userData.email"
                    type="email"
                    style="width:100%;"
                    class="form-control form-control-lg form-control-solid"
                />
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Password</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="password">
                <input
                    v-model="userData.password"
                    type="password"
                    style="width:100%;"
                    class="form-control form-control-lg form-control-solid"
                />
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Confirm Password</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="password">
                <input
                    v-model="confirmPassword"
                    type="password"
                    style="width:100%;"
                    class="form-control form-control-lg form-control-solid"
                />
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
              >
                <span>Role</span>
              </label>
              <div class="col-lg-9">
                <el-form-item prop="name">
                <select
                  name="role"
                  class="form-select form-select-solid form-select-lg fw-bold"
                  v-model="userData.role"
                >
                  <option v-for="(role, index) in roles" :key="index" :value="role">
                    {{role}}
                  </option>
                </select>
                </el-form-item>
              </div>
            </div>

            <div class="text-center">
              <button
                id="kt_modal_new_target_cancel"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>

              <el-button type="primary" @click="submit()" :loading="loading">
                {{ loading ? "Please wait..." : "Submit" }}
              </el-button>
            </div>
            </el-form>
          </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { User } from "@/types/UserModel";

export default defineComponent({
  name: "create-record-modal",
  components: {
  },
  props:{
    user : User,
    roles : [],
    reloadUsers: {
      type:Function,
      default: () => ({}),
    }, 
  },
  emits:['updateUser'],
  setup(props, {emit}) {
    const userData = ref({} as any);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const confirmPassword = ref('');

    watch(() => props.user, async()=>{
        userData.value = props.user;
    });

    const editUser = async (formData) => {
        ApiService.setHeader();
        if(userData.value && userData.value.id !==0)
        {
            ApiService.update("user",userData.value.id, formData)
                .then(()=>{
                    userData.value.password= '';
                    confirmPassword.value = '';
                    emit('updateUser', userData);
                })
                .catch(({ response }) => {
                    loading.value = false;
                    console.log(response);
                    Swal.fire({
                    text: "Failed to edit User",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
        else
        {
          ApiService.post("user", formData)
                .then(()=> { props.reloadUsers();})
                .catch(({ response }) => {
                    loading.value = false;
                    console.log(response);
                    Swal.fire({
                    text: "Failed to create the new user",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    });
                });
        }
    }

    const submit = () => {
        let errorMessage = '';
        if(!userData.value.name || !userData.value.email ){
            errorMessage = "Please fill out all required fields";
        }
        else if((userData.value.id > 0 && userData.value.password && userData.value.password != confirmPassword.value)
                ||(userData.value.id === 0 && userData.value.password != confirmPassword.value)){
            errorMessage = 'password and confirmed password are not matched';
        }
        else if(userData.value.id > 0 && !userData.value.password){
            delete userData.value.password;
        }

        if(errorMessage !== ''){
            Swal.fire({
                text: errorMessage,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            });

            return false;
        }
        else{
            loading.value = true;
            editUser(userData.value).then(() => {
                Swal.fire({
                    text: "User change successfully submitted!",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Ok, got it!",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                }).then(() => {
                    let userDetailCancelBtn = document.getElementById('kt_modal_new_target_cancel');
                    userDetailCancelBtn?.click();
                });
            });
        }
    };

    return {
      userData,
      submit,
      loading,
      formRef,
      confirmPassword
    };
  },
});
</script>

<style scoped>
* >>> .el-form-item {
  margin-bottom: 0px;
}

* >>> .el-table td {
    padding: 5px;
    font-size: 13px;
}

* >>> .el-table th {
    padding: 5px;
    font-size: 13px;
    line-height: 20px;
}
</style>