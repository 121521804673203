<template>
  <div class="d-flex flex-stack" style="padding-bottom:10px;">
    <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
    </div>
    <div class="col-md-8" align="right">
      <div class="me-4">
        <el-input
            v-model="search.searchValue"
            placeholder="Type to search"
            class="col-md-8"
            style="padding-right:10px;"/>
        <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#modal_user_detail"
            id="user_detail_button"
            style="display:none"
        ></a>
        <button class="btn btn-sm btn-primary" v-on:click="userDetail({id:0})" >
            Create new user
        </button>
      </div>
    </div>
  </div>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="student_list">
    <el-table :data="pagedTableDate" style="width: 100%">
      <el-table-column prop="name" label="name"></el-table-column>
      <el-table-column prop="email" label="email"></el-table-column>
      <el-table-column prop="role" label="role"></el-table-column>
      <el-table-column prop="operation" label="" width="180">
        <template v-slot="scope">
            <button class="btn btn-sm btn-success" title="Delete" 
            @click="handleEdit(scope.row)" style="margin-right:5px;">Edit</button>        
            <button class="btn btn-sm btn-danger" title="Delete" 
            @click="handleDelete(scope.row)">Delete</button>
        </template>
      </el-table-column>     
    </el-table>
    <div align="right">
      <el-pagination layout="prev, pager, next" v-model:page-size="pageSize" :total="users.length" @current-change="setPage"></el-pagination>
    </div>
  </div>
  <div v-if="roles">
    <UserDetail 
        :user="selectedUser"
        :roles="roles"
        @updateUser="updateUser"
        :reloadUsers="getUsers" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, reactive, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import UserDetail from "@/views/user/modals/UserDetail.vue"
import { User } from "@/types/UserModel";
import { ElMessageBox } from "element-plus";
import Swal from "sweetalert2";

export default defineComponent({
  name: "user-list",
  components: {
      UserDetail
  },
  setup() {
    const users = ref<any>([]);;
    const selectedUser = ref({} as User);
    const roles = ref<any>([]);

    const getRoles = async() =>{
        ApiService.setHeader(); 
        await ApiService.get("user/roles").then(({data})=>{
            roles.value = data.data;
        })
    }

    const getUsers = async () =>  {
        ApiService.setHeader();         
        ApiService.get("user")
            .then(({ data }) => {
                users.value = data.data;
            })
            .catch(({ response }) => {
                console.log('getUsers error => '+response.data.errors);                    
            });
    }

    const pageSize = 15;
    let page = reactive({pageValue : 1});

    const pagedTableDate = computed(()=>{
      return users.value.filter(data => !search 
                                || data.name.toLowerCase().includes(search.searchValue.toLowerCase())
                                || data.email.toLowerCase().includes(search.searchValue.toLowerCase())
                                || data.role?.toLowerCase().includes(search.searchValue.toLowerCase()))
                            .slice(pageSize * page.pageValue - pageSize, pageSize * page.pageValue);
     })

    const search = reactive({searchValue : ''});

    const userDetail = (user) =>{
        selectedUser.value = user;
        let userDetailBtn = document.getElementById('user_detail_button');
        userDetailBtn?.click();
    }

    const updateUser = (user) =>{
        users.value.find(x=> x.id == user.id)[0] = user;
    }

    const handleEdit = (row) => {
        userDetail(row)
    };

    const handleDelete = (row) => {
      ElMessageBox.confirm('Are you sure to delete this user?')
            .then(() => {
                ApiService.setHeader();
                ApiService.delete("user/"+row.id)
                    .then(() => {
                        Swal.fire({
                            text: "user is deleted successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                users.value = users.value.filter(x=>x.id != row.id);
                            });
                    })
                    .catch(({ response }) => {
                        Swal.fire({
                        text: "Failed to delete the assessment. "+response.data.errors,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        })
                    });
            });
    };

    const setPage = (val) => {
      page.pageValue = val;
    }

    onBeforeMount(async() =>{
        await getRoles();
        await getUsers();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("User List", ["User"]);
    });

    return {
      handleEdit,
      handleDelete,
      setPage,
      userDetail,
      users,
      pagedTableDate,
      pageSize,
      search,
      roles,
      selectedUser,
      updateUser,
      getUsers
    };
  },
});
</script>

<style scoped>
  .el-table__row{
    cursor:pointer;
  }
</style>

